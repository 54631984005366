<template>
  <b-container fluid >
    <b-row>
      <b-col md="12" class="allCardList">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">
              <i class="fa-solid fa-graduation-cap" aria-hidden="true" style="font-size: 21px; margin-right: 3px; color: var(--iq-primary);"></i>
              {{cvCardTitle}}
              <span class="btn_in_header">
              <span class="pull-right" v-if="userData.user_role=='USERROLE11111' || userData.user_role=='USERROLE11118'">
                <b-button variant="primary" @click="careerAdd">Add (+)</b-button>
              </span>
              </span>
            </h4>
          </template>
        </iq-card>

        <iq-card>
          <b-col md="12" class="p-0">
            <iq-card style="width:100%;" >
              <div class="p-0 showFlexListDiv">
                <div class="user-tabing showFlexList">
                  <tab-nav :pills="true" id="pills-tab" class="nav nav-pills d-flex align-items-center justify-content-center profile-feed-items p-0 m-0 w-100">
                    <div  class="flex-fill p-0" @click="switchTabs('allCareers')">
                      <tab-nav-items class="flex-fill p-0" :active="allCareerActive" id="pills-all-careers-tab" href="#profile-all-careers" ariaControls="pills-all-careers" role="tab" :ariaSelected="allCareerActive" title="All Careers" />
                    </div>
                    <div  class="flex-fill p-0" @click="switchTabs('followedCareers')" >
                      <tab-nav-items  class="flex-fill p-0" :active="followingCareerActive" id="pills-following-careers-tab"  href="#profile-following-careers"  ariaControls="pills-following-careers" role="tab" :ariaSelected="followingCareerActive" title="Saved Career" />
                    </div>
                  </tab-nav>
                </div>
              </div>
            </iq-card>
          </b-col>
        </iq-card>

        <iq-card>
          <div class="rounded p-2 bg-white" >
            <b-form-input v-model="vmCarSearch" placeholder="Search" @input="careersList(vmCarSearch)">
            </b-form-input>
          </div>
        </iq-card>

        <b-col sm="12" class="p-0">
          <div class="tab-content">
            <tab-content-item :active="true" id="profile-all-careers" aria-labelled-by="pills-all-careers-tab">
              <div v-if="Object.values(careerListObj).length >0">
                <b-row class="p-2">
                  <div v-for="(carr,index) in careerListObj" :key="index" class="mt-5 col-12 col-sm-6 col-md-4 col-lg-3">
                    <iq-card className="iq-card-block iq-card-stretch iq-card-height side_border">
                      <template v-slot:body>
                        <div class="iq-badges text-left" @click="goToCareerView(carr.carr_id)" style="cursor: pointer;">
                          <div class="badges-icon">
                            <b-avatar style="border-radius: 100%!important;" class="avatar-80 rounded" v-if="carr.carr_profile_image">
                              <img :src="carr.carr_profile_image" class="avatar-80 rounded" alt="career_image">
                            </b-avatar>
                            <b-avatar v-else class="avatar-80 rounded" :text="getFirstLetterOfAString(carr.carr_field)" style="border-radius: 100%!important;">
                            </b-avatar>
                          </div>
                          <h5 class="mb-2 title twoLineOnly" :title="carr.carr_field">
                            {{carr.carr_field}}
                          </h5>
                          <div class="d-flex justify-content-between" style="float:right">
                              <button style="all:unset; font-weight: 500; cursor: pointer;" @click="followCareer($event, carr.carr_id, careerFollowObj[carr.carr_id])">
                                <span v-if="!careerFollowObj[carr.carr_id]" class="text-primary">Save</span>
                                <span v-else class="text-secondary">Saved</span>
                              </button>
                          </div>
                        </div>
                      </template>
                    </iq-card>
                  </div>
                </b-row>
                <div class="d-flex justify-content-center flex-row flex-fill mb-4" v-if="showLoadMoreBtn && currentPage !== totalPage">
                  <button class="btn btn-primary mb-3" @click="loadMore('allCareers')">
                    Load More
                  </button>
                </div>
              </div>
              <div v-else class="p-2 text-center flex flex-fill justify-content-center">
                No Data Found
              </div>
            </tab-content-item>
            <tab-content-item :active="false" id="profile-following-careers" aria-labelled-by="pills-following-careers-tab">
              <b-row class="p-2">
                <div v-for="(carr,index) in careerFollowObj" :key="index" class="mt-5 col-12 col-sm-6 col-md-4 col-lg-3">
                  <iq-card className="iq-card-block iq-card-stretch iq-card-height side_border">
                    <template v-slot:body>
                      <div class="iq-badges text-left" @click="goToCareerView(carr.carr_id)" style="cursor: pointer;">
                        <div class="badges-icon">
                          <b-avatar style="border-radius: 100%!important;" class="avatar-80 rounded" >
                            <img :src="carr.carr_profile_image" class="avatar-80 rounded" alt="career_follow">
                          </b-avatar>
                        </div>
                        <h5 class="mb-2 title twoLineOnly" :title="carr.carr_field">
                          {{carr.carr_field}}
                        </h5>
                        <div class="d-flex justify-content-between" style="float:right">
                          <button style="all:unset;float:right; font-weight: 500; cursor: pointer;" @click="followCareer($event, carr.carr_id, careerFollowObj[carr.carr_id])">
                            <span class="text-primary">Unsave</span>
                          </button>
                        </div>
                      </div>
                    </template>
                  </iq-card>
                </div>
              </b-row>
            </tab-content-item>
          </div>
        </b-col>
        <b-toast v-model="showToast" name="Toast" :variant="toastVariant" :title="toastTitle">
          <div v-html="toastMsg">
          </div>
        </b-toast>
      </b-col>
    </b-row>
  </b-container>
</template>
<style scoped >
  .iq-card-stretch{
    border-radius: 20px;
  }
</style>
<script>
import { socialvue } from "../../../../config/pluginInit"
import { Careers } from "../../../../FackApi/api/career.js"
import { Follows } from "../../../../FackApi/api/follow"
import moment from "moment"
import ApiResponse from "../../../../Utils/apiResponse.js"
import Utility from "../../../../Utils/utility"

export default {
  name: "careersList",
  data () {
    return {
      soicalInfo: [
        {
          name: "My Careers"
        },
        {
          name: "All Careers"
        }
      ],
      cvCardTitle: "Careers",
      careerListObj: {},
      allCareerActive: true,
      followingCareerActive: false,
      careerImgObj: {},
      careerFollowObj: {},
      allCareerCount: 0,
      currentPage: 1,
      totalPage: "",
      showLoadMoreBtn: false,
      vmCarSearch: "",
      vmFollowData: Object.assign({}, this.initFollowData()),
      showToast: false,
      toastMsg: null,
      toastVariant: "default",
      toastTitle: "Career Follow Response",
      vmBannerImage: Utility.getBgImageForLetter(this, "CARR")
    }
  },
  computed: {
    userData () {
      return this.$store.getters["User/userData"]
    },
    careers () {
      return this.$store.getters["Career/selectedCareer"]
    }
  },
  mounted () {
    this.loadRequiredData()
  },
  methods: {
    /**
     * initFollowData
     */
    initFollowData () {
      return {
        module_id: "",
        module_name: "",
        follow_status: 0
      }
    },
    /**
     * loadRequiredData
     */
    loadRequiredData () {
      socialvue.index()
      this.careersList()
      this.careersFollowedList()
    },
    /**
     * organisationAdd
     */
    careerAdd () {
      try {
        this.$router.push("/career_add")
      }
      catch (err) {
        console.error("Exception occurred at careerAdd() and Exception:", err.message)
      }
    },
    /**
     * getFormattedDate
     */
    getFormattedDate (datetime) {
      try {
        return moment(datetime).format("DD-MM-YYYY hh:mm:ss")
      }
      catch (err) {
        console.error("Exception occurred at getFormattedDateTime() and Exception:", err.message)
      }
    },
    /**
     * getFirstLetterOfAString
     */
    getFirstLetterOfAString (string) {
      if (string) {
        return string.substr(0, 1)
      }
    },
    /**
     * getResizedText
     */
    getResizedText (text) {
      return text ? text.substr(0, 100) : ""
    },
    /**
     * goToCareerView
     */
    goToCareerView (carrId) {
      this.$router.push("/career/" + carrId)
    },
    /*
   * careersList
   * */
    async careersList (searchString = null, loadViaLoadMore = false) {
      try {
        let filter = {}
        if (searchString && searchString.length) {
          filter = {
            searchString: searchString
          }
        }
        // get the data from localStore
        let storeData = await this.careers

        if (storeData && (!searchString || searchString == "") && !loadViaLoadMore) {
          // If the data is present in the store, no search is happening, and the data is not loaded via the Load More button, then set all the variables from the store itself
          if (storeData.currentPage) {
            this.currentPage = parseInt(storeData.currentPage)
          }

          if (storeData.totalPage) {
            this.totalPage = parseInt(storeData.totalPage)
          }

          if (this.currentPage && this.totalPage && this.currentPage >= this.totalPage) {
            this.showLoadMoreBtn = false
          }
          else {
            this.showLoadMoreBtn = true
          }

          if (this.currentPage === 1 || searchString) {
            this.careerListObj = { ...storeData.careerListObj }
          }
          else {
            this.careerListObj = { ...this.careerListObj, ...storeData.careerListObj }
          }
          this.allCareerCount = storeData.totalCount
        }
        else {
          let careersListResp

          if (searchString) {
            careersListResp = await Careers.careerList(this, "", filter)
          }
          else {
            careersListResp = await Careers.careerList(this, this.currentPage, filter)
          }

          if (careersListResp && careersListResp.resp_status) {
            if (careersListResp.resp_data.currentPage) {
              this.currentPage = parseInt(careersListResp.resp_data.currentPage)
            }

            if (careersListResp.resp_data.totalPage) {
              this.totalPage = parseInt(careersListResp.resp_data.totalPage)
            }

            if (this.currentPage && this.totalPage && this.currentPage >= this.totalPage) {
              this.showLoadMoreBtn = false
            }
            else {
              this.showLoadMoreBtn = true
            }

            this.allCareerCount = careersListResp.resp_data.totalCount

            if (this.currentPage === 1 || searchString) {
              this.careerListObj = { ...careersListResp.resp_data.data }
            }
            else {
              this.careerListObj = { ...this.careerListObj, ...careersListResp.resp_data.data }
            }

            if (!searchString) {
              let careerItemListObj = {
                careerListObj: this.careerListObj,
                careerListObj_allCareerCount: this.allCareerCount,
                currentPage: this.currentPage,
                totalPage: this.totalPage
              }
              this.$store.commit("Career/addCareer", careerItemListObj)
            }
          }
          else {
            this.careerListObj = {}
          }
        }
      }
      catch (err) {
        console.error("Exception occurred at careersList() and Exception:", err.message)
      }
    },
    /*
     * careersFollowedList
     */
    async careersFollowedList () {
      try {
        let careersFollowedListResp = await Careers.careerFollowedList(this, this.currentPage)
        if (careersFollowedListResp && careersFollowedListResp.resp_status) {
          this.careerFollowObj = careersFollowedListResp.resp_data.data
        }
        else {
          this.careerFollowObj = {}
        }
      }
      catch (err) {
        console.error("Exception occurred at careersFollowedList() and Exception:", err.message)
      }
    },
    /**
     *  carrersityFollow ()
     */
    async followCareer (event, moduleId, followStatus) {
      event.stopPropagation()
      // Here I am optimystically updating the UI for better user experience
      if (followStatus) {
        delete this.careerFollowObj[moduleId]
      }
      else {
        this.careerFollowObj[moduleId] = this.careerListObj[moduleId]
      }
      try {
        this.vmFollowData.module_id = moduleId
        this.vmFollowData.module_name = "CARR"
        this.vmFollowData.follow_status = followStatus ? 0 : 1
        let followResp = await Follows.followAdd(this, this.vmFollowData)
        await ApiResponse.responseMessageDisplay(this, followResp)
        if (followResp && !followResp.resp_status) {
          // If for any reason the followAdd api fails I will undo the UI updates
          if (followStatus) {
            this.careerFollowObj[moduleId] = this.careerListObj[moduleId]
          }
          else {
            delete this.careerFollowObj[moduleId]
          }
          return
        }
        this.vmFollowData = Object.assign({}, this.initFollowData())
      }
      catch (err) {
        console.error("Exception occured at followCareer() and Exception", err.message)
      }
    },
    /**
     * switchTabs
     */
    async switchTabs (currentTab) {
      try {
        if (this.allCareerActive && currentTab === "allCareers") {
          return
        }
        else if (this.followingCareerActive && currentTab === "followedCareers") {
          return
        }
        else {
          let currPage = 1
          this.currentPage = currPage
          this.totalPage = ""
          if (currentTab === "allCareers") {
            this.allCareerActive = true
            this.followingCareerActive = false
            await this.careersList()
          }
          else if (currentTab === "followedCareers") {
            this.allCareerActive = false
            this.followingCareerActive = true
            await this.careersFollowedList()
          }
        }
      }
      catch (err) {
        console.error("Exception occured at switchTabs() and Exception", err.message)
      }
    },
    /**
     * loadMore()
     */
    loadMore (currentTab) {
      try {
        if (this.currentPage >= this.totalPage) {
          this.showLoadMoreBtn = false
          return
        }
        else {
          let currPage = this.currentPage
          this.currentPage = parseInt(currPage) + 1
          if (this.vmCarSearch && currentTab === "allCareers") {
            this.careersList(this.vmCarSearch)
          }
          else if (currentTab === "allCareers") {
            this.careersList(null, true)
            // Passing searchString = null, loadViaLoadMore = true so as not to use the storeData and make an api call
          }
        }
      }
      catch (err) {
        console.error("Exception occured at loadMore() and Exception", err.message)
      }
    }
  }
}
</script>
