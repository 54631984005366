/*eslint-disable */
// import CareerModel from '../../Model/Career'
import request from '../../Utils/curl'
import Utility from "../../Utils/utility"
let Careers = {
  /**
   * careerList
   */
  async careerList (context, currentPage='', filter = {}, searchString='') {
    Utility.showLoader = true;
    Utility.loadingMsg = "Please wait...."; 
    try{
      let post_data = new FormData();
      if (currentPage) {
        post_data.append('currentPage', currentPage)
      }

      if (Object.keys(filter).length > 0) { 
        post_data.append('filter', JSON.stringify(filter))
      }

      return await request.curl(context, "career_list", post_data)
        .then(async response => {
          return response
        });

    }
    catch(err){
      console.error('Exception occurred at careerList() and Exception:',err.message)
    }
    finally{
      Utility.showLoader = false;
    }
  },
  /**
   * careerLinkListCustom
  */
  async careerLinkListCustom (context, carr_field, carr_pba_type, currentPage='', filter = {},) {
    try{
      let post_data = new FormData();
      if (currentPage) {
        post_data.append('currentPage', currentPage)
      }

      if (Object.keys(filter).length > 0) { 
        post_data.append('filter', JSON.stringify(filter))
      }
      post_data.append('carr_field', carr_field);
      post_data.append('carr_pba_type', carr_pba_type);

      return await request.curl(context, "carrerPba_list_custom", post_data)
        .then(async response => {
          return response
        });

    }
    catch(err){
      console.error('Exception occurred at careerList() and Exception:',err.message)
    }
  },
   /**
   * careerFollowedList
   */
  async careerFollowedList (context, currentPage='', filter = {}) {
    try{
      let post_data = new FormData();
      
      if (currentPage) {
        post_data.append('currentPage', currentPage)
      }

      if (Object.keys(filter).length > 0) {
        post_data.append('filter', JSON.stringify(filter))
      }
  
      return await request.curl(context, "career_list_followed", post_data)
        .then(async response => {
          return response
        });

    }
    catch(err){
      console.error('Exception occurred at careerFollowedList() and Exception:',err.message)
    }
  },
  /**
   * CareerFieldList
   */
   async careerFieldList (context) {
    try{
      let post_data = new FormData();
      return await request.curl(context, "career_field_list", post_data)
        .then(async response => {
          return response
        });

    }
    catch(err){
      console.error('Exception occurred at careerFieldList() and Exception:',err.message)
    }
  },  
  /**
   * careerView
   */
  async careerView (context, careerId) {
    try {      
      let post_data = new FormData();
      post_data.append('carr_id', careerId);
      return await request.curl(context, "career_view", post_data)
      .then(async response => {
        return response;
      });
    }
    catch (err) {
      console.error("Exception occurred at careerList() and Exception:",err.message)
    }    
  },
  /**
   * careerAdd
   */
  async careerAdd (context, careerObj) {
    try{
    let post_data = new FormData();
    for (let key in careerObj) {
      if (key === 'carr_opp' || key === 'carr_exam') {
        if (careerObj[key] && careerObj[key].length > 0) {
          post_data.append(key, JSON.stringify(careerObj[key]));
        }
      }
      else {
        post_data.append(key, careerObj[key]);
      }
    }

    return await request.curl(context, "career_add", post_data)
      .then(async response => {
        return response;
      });
    }
    catch(err){
      console.error('Exception occurred at careerAdd() and Exception:',err.message)
    }
  },
  /**
   * careerEdit
   */
  async careerEdit (context, careerObj) {
    try{
    let post_data = new FormData();

    for (let key in careerObj) {
      if (key === 'carr_opp' || key === 'carr_exam') {
        if (careerObj[key] && careerObj[key].length > 0) {
          post_data.append(key, JSON.stringify(careerObj[key]));
        }
      }
    else {
        post_data.append(key, careerObj[key]);
      }
    }

    return await request.curl(context, "career_edit", post_data)
      .then(async response => {
        return response;
      });
    }
    catch(err){
      console.error('Exception occurred at careerEdit() and Exception:',err.message)
    }
  },
  /**
   * careerDelete
   */
  async careerDelete (context, careerId) {
    try{
    let post_data = new FormData();
    
    post_data.append('carr_id', careerId);

    return await request.curl(context, "career_delete", post_data)
      .then(async response => {
        return response;
      });
    }
    catch(err){
      console.error('Exception occurred at careerDelete() and Exception:',err.message)
    }
  }
}

export {
  Careers
}
