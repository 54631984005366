/*eslint-disable */
// import FollowModel from '../../Model/Follow'
import request from '../../Utils/curl'

let Follows = {
  /**
   * followList
   */
  async followList (context, moduleId, currentPage) {
    try{
      let post_data = new FormData();
      post_data.append('module_id', moduleId)
      post_data.append('currentPage', currentPage)

      return await request.curl(context, "follow_list", post_data)
        .then(async response => {
          return response
        });

    }
    catch(err){
      console.error('Exception occurred at followList() and Exception:',err.message)
    }
  },

  /**
   * followView
   */
  async followView (context, followId) {
    try {
      
      let post_data = new FormData();
      post_data.append('follow_id', followId);
      return await request.curl(context, "follow_view", post_data)
      .then(async response => {
        return response;
      });
    }
    catch (err) {
      console.error("Exception occurred at followView() and Exception:",err.message)
    }
    
  },

  /**
   * followAdd
   */
  async followAdd (context, followObj) {
    try{
    let post_data = new FormData();
    
    for (let key in followObj) {
      post_data.append(key, followObj[key]);
    }

    return await request.curl(context, "follow_add", post_data)
      .then(async response => {
        return response;
      });
    }
    catch(err){
      console.error('Exception occurred at followAdd() and Exception:',err.message)
    }
  },

  /**
   * followEdit
   */
  async followEdit (context, followObj) {
    try{
    let post_data = new FormData();
    
    for (let key in followObj) {
      post_data.append(key, followObj[key]);
    }

    return await request.curl(context, "follow_edit", post_data)
      .then(async response => {
        return response;
      });
    }
    catch(err){
      console.error('Exception occurred at followEdit() and Exception:',err.message)
    }
  },

  /**
   * followDelete
   */
  async followDelete (context, followId) {
    try{
    let post_data = new FormData();
    
    post_data.append('follow_id', followId);

    return await request.curl(context, "follow_delete", post_data)
      .then(async response => {
        return response;
      });
    }
    catch(err){
      console.error('Exception occurred at followDelete() and Exception:',err.message)
    }
  }
}

export {
  Follows
}
